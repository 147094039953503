import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './PopupwindowFiltersMobile.css'
import { PiCaretDownThin } from "react-icons/pi"
import { MdOutlineArrowDropDown } from "react-icons/md"
import FormDropdownPopup from './FormDropdownPopup'
import { activity_types_svgs } from "../../assets/Data"
import Slider from '../Slider'
import ClearIcon from '@mui/icons-material/Clear'

function PopupwindowFiltersMobile({
  children, id, isActive, setIsActive, filters, updateFilters,
  refreshState, arrayOfCities, arrayOfPostalCodes, myTeamArray,
  suppliersArray, stateType
}) {
  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.user.userInfo)
  const customerTypesArray = useSelector(state => state.user.customer_types)

  const [searchValue, setSearchValue] = useState(filters.searchWord || "")
  const [debouncedSearchWord, setDebouncedSearchWord] = useState(searchValue)
  const [dropdownPopupIsOpen, setDropdownPopupIsOpen] = useState(false)
  const [dropdownList, setDropdownList] = useState([])
  const [dropdownID, setdropdownID] = useState("")
  const [hasSearchBar, setHasSearchBar] = useState(false)

  const thisPopupContainerRef = useRef(null)
  const saveButtonRef = useRef(null)

  const arrayOfTypes = Object.keys(customerTypesArray)
  const arrayOfSubTypes = customerTypesArray[filters.typeFilter] || []
  const arrayOfActivityTypes = Array.from(Object.keys(activity_types_svgs))
  const prioritiesArray = [
    { id: 1, name: "1: important & urgent" },
    { id: 2, name: "2: important & not urgent" },
    { id: 3, name: "3: not important & urgent" },
    { id: 4, name: "4: not important & not urgent" }
  ]

  // Synchronize searchValue with Redux state on mount and when filters.searchWord changes
  useEffect(() => {
    setSearchValue(filters.searchWord)
  }, [filters.searchWord])

  // Debouncing logic
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (debouncedSearchWord !== searchValue) {
        setDebouncedSearchWord(searchValue)
      }
    }, 750) // 750ms delay

    return () => {
      clearTimeout(timerId)
    }
  }, [searchValue])

  // Set redux state after debouncing
  useEffect(() => {
    if (debouncedSearchWord !== undefined && debouncedSearchWord !== filters.searchWord) {
      const updatedFilters = { ...filters }
      updatedFilters['searchWord'] = debouncedSearchWord

      if (stateType === "redux") {
        dispatch(updateFilters(updatedFilters))
      } else if (stateType === "useState") {
        updateFilters(updatedFilters)
      }
    }
  }, [debouncedSearchWord])

  const handleOnchangeSearch = (e) => {
    setSearchValue(e.currentTarget.value)
  }

  const handleClearSearch = () => {
    setSearchValue("")
  }

  const handleClosePopup = () => {
    setIsActive(false)
  }

  const handleOnClickPopup = (e, type) => {

    switch (type) {
      case "sales-supplier":
        setDropdownList(suppliersArray)
        setdropdownID("sales-supplier")
        setHasSearchBar(false)
        break
      case "sales-status":
        setDropdownList(["To accept", "To deliver", "To invoice", "Done"])
        setdropdownID("sales-status")
        setHasSearchBar(false)
        break
      case "activity-owner":
        setDropdownList(myTeamArray)
        setdropdownID("activity-owner")
        setHasSearchBar(false)
        break
      case "activity-priority":
        setDropdownList(prioritiesArray)
        setdropdownID("activity-priority")
        setHasSearchBar(false)
        break
      case "activity-type":
        setDropdownList(arrayOfActivityTypes)
        setdropdownID("activity-type")
        setHasSearchBar(false)
        break
      case "type":
        setDropdownList(arrayOfTypes)
        setdropdownID("customer-type")
        setHasSearchBar(false)
        break
      case "sub-type":
        setDropdownList(arrayOfSubTypes)
        setdropdownID("customer-sub-type")
        setHasSearchBar(false)
        break
      case "city":
        setDropdownList(arrayOfCities)
        setdropdownID("customer-city")
        setHasSearchBar(true)
        break
      case "pc":
        setDropdownList(arrayOfPostalCodes)
        setdropdownID("customer-pc")
        setHasSearchBar(true)
        break
      case "list-type":
        setDropdownList(["All", "Have my products", "Don't have my products"])
        setdropdownID("list-type")
        setHasSearchBar(false)
        break
      default:
        return
    }
    setDropdownPopupIsOpen(true)
  }

  const handleSetFilterState = (id, value) => {

    const newFiltersObj = { ...filters }

    switch (id) {
      case "sales-supplier":
        newFiltersObj.supplierFilter = value
        break
      case "sales-status":
        newFiltersObj.statusFilter = value
        break
      case "ascending-filter":
        newFiltersObj.sortAscending = value
        break
      case "done-filter":
        newFiltersObj.doneFilter = value
        break
      case "activity-owner":
        newFiltersObj.ownerFilter = value
        break
      case "activity-priority":
        newFiltersObj.priorityFilter = value
        break
      case "activity-type":
        newFiltersObj.typeFilter = value
        break
      case "customer-type":
        newFiltersObj.typeFilter = value
        break
      case "customer-sub-type":
        newFiltersObj.subTypeFilter = value
        break
      case "customer-city":
        newFiltersObj.cityFilter = value
        break
      case "customer-pc":
        newFiltersObj.postalCodeFilter = value
        break
      case "B2C-filter":
        newFiltersObj.filterB2C = value
        break
      case "list-type":
        newFiltersObj.customersListType = value
        break
      case "hide-unavailable-filter":
        newFiltersObj.hideUnavailable = value
    }

    if (stateType === "redux") {
      dispatch(updateFilters(newFiltersObj))
    } else if (stateType === "useState") {
      updateFilters(newFiltersObj)
    }
  }

  return (
    <div className={`popup_window__container filters ${isActive ? "active" : ""}`} id={id} ref={thisPopupContainerRef}>

      <FormDropdownPopup
        id={dropdownID}
        type="filter-popup"
        list={dropdownList}
        isOpen={dropdownPopupIsOpen}
        setIsOpen={setDropdownPopupIsOpen}
        setValue={handleSetFilterState}
        searchBar={hasSearchBar}
      />

      <div className='popup_header'>
        <div className='go_back' onClick={handleClosePopup}><PiCaretDownThin /></div>
        {/* <button className='green-button-header' ref={saveButtonRef}>SAVE</button> */}
      </div>
      <div className='popup_body'>
        <div className='content_wrapper'>
          {
            id === "popup-window-filters--customers-page" &&
            <>
              <div className='popup_filter__searchbar_wrapper'>
                <input className='popup_filter__searchbar' value={searchValue} onChange={handleOnchangeSearch} placeholder='Search...' />
                {
                  searchValue ? <ClearIcon onClick={handleClearSearch} /> : null
                }
              </div>
              <div className='form_dropdown--mobile' name='type' onClick={(e) => handleOnClickPopup(e, "type")}>
                {
                  filters.typeFilter ?
                    <p>{filters.typeFilter}</p> :
                    <p className='placeholder'>Type</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              <div className='form_dropdown--mobile' name='sub-type' onClick={(e) => handleOnClickPopup(e, "sub-type")}>
                {
                  filters.subTypeFilter ?
                    <p>{filters.subTypeFilter}</p> :
                    <p className='placeholder'>Sub type</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              <div className='form_dropdown--mobile' name='pc' onClick={(e) => handleOnClickPopup(e, "pc")}>
                {
                  filters.postalCodeFilter ?
                    <p>{filters.postalCodeFilter}</p> :
                    <p className='placeholder'>Zip code</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              <div className='form_dropdown--mobile' name='city' onClick={(e) => handleOnClickPopup(e, "city")}>
                {
                  filters.cityFilter ?
                    <p>{filters.cityFilter}</p> :
                    <p className='placeholder'>City</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              <div className='form_dropdown--mobile' name='list-type' onClick={(e) => handleOnClickPopup(e, "list-type")}>
                {
                  filters.customersListType ?
                    <p>{filters.customersListType}</p> :
                    <p className='placeholder'>List type</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              <div className='sliders'>
                <Slider
                  idNr={"B2C-filter"}
                  state={filters.filterB2C}
                  onClickFunction={handleSetFilterState}
                  size={2}
                  fontSize={"1rem"}
                  theme="dark--mobile"
                  text="Hide B2C"
                />
              </div>
            </>
          }
          {
            id === "popup-window-filters--customer-info-page" &&
            <>
              <div className='popup_filter__searchbar_wrapper'>
                <input className='popup_filter__searchbar' value={searchValue} onChange={handleOnchangeSearch} placeholder='Search...' />
                {
                  searchValue ? <ClearIcon onClick={handleClearSearch} /> : null
                }
              </div>
              <Slider
                idNr={"hide-unavailable-filter"}
                state={filters.hideUnavailable}
                onClickFunction={handleSetFilterState}
                size={2}
                fontSize={"1rem"}
                theme="dark--mobile"
                text="Hide unavailable"
              />
            </>
          }

          {
            id === "popup-window-filters--activities-page" &&
            <>
              <div className='popup_filter__searchbar_wrapper'>
                <input className='popup_filter__searchbar' value={searchValue} onChange={handleOnchangeSearch} placeholder='Search...' />
                {
                  searchValue ? <ClearIcon onClick={handleClearSearch} /> : null
                }
              </div>
              <div className='form_dropdown--mobile' name='activity-type' onClick={(e) => handleOnClickPopup(e, "activity-type")}>
                {
                  filters.typeFilter ?
                    <p>{filters.typeFilter}</p> :
                    <p className='placeholder'>Type</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              <div className='form_dropdown--mobile' name='activity-priority' onClick={(e) => handleOnClickPopup(e, "activity-priority")}>
                {
                  filters.priorityFilter?.name ?
                    <p>{filters.priorityFilter.name}</p> :
                    <p className='placeholder'>Priority</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              <div className='form_dropdown--mobile' name='activity-owner' onClick={(e) => handleOnClickPopup(e, "activity-owner")}>
                {
                  filters.ownerFilter?.name ?
                    <p>{filters.ownerFilter.name}</p> :
                    <p className='placeholder'>Owner</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              <div className='sliders'>
                <Slider
                  idNr={"done-filter"}
                  state={filters.doneFilter}
                  onClickFunction={handleSetFilterState}
                  size={2}
                  fontSize={"1rem"}
                  theme="dark--mobile"
                  text="Hide done"
                />
                <Slider
                  idNr={"ascending-filter"}
                  state={filters.sortAscending}
                  onClickFunction={handleSetFilterState}
                  size={2}
                  fontSize={"1rem"}
                  theme="dark--mobile"
                  text="Oldest first"
                />
              </div>
            </>
          }
          {
            id === "popup-window-filters--sales-page-brandowners" &&
            <>
              <div className='popup_filter__searchbar_wrapper'>
                <input className='popup_filter__searchbar' value={searchValue} onChange={handleOnchangeSearch} placeholder='Search...' />
                {
                  searchValue ? <ClearIcon onClick={handleClearSearch} /> : null
                }
              </div>
              <div className='form_dropdown--mobile' name='sales-status' onClick={(e) => handleOnClickPopup(e, "sales-status")}>
                {
                  filters.statusFilter ?
                    <p>{filters.statusFilter}</p> :
                    <p className='placeholder'>Status</p>
                }
                <MdOutlineArrowDropDown />
              </div>
              {
                ["manager"].includes(userInfo.type) &&
                <div className='form_dropdown--mobile' name='sales-supplier' onClick={(e) => handleOnClickPopup(e, "sales-supplier")}>
                  {
                    filters.supplierFilter ?
                      <p>{filters.supplierFilter}</p> :
                      <p className='placeholder'>Supplier</p>
                  }
                  <MdOutlineArrowDropDown />
                </div>
              }
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default PopupwindowFiltersMobile

PopupwindowFiltersMobile.defaultProps = {
  id: "popup-window-mobile",
  stateType: "redux",
}
