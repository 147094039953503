import React, { useState, useEffect } from 'react'
import './InputCustomDetails.css'

function InputCustomDetails({ index, text, textPosition, inputBoxSize, fontSize, inputType, value, state, setState, id, onChangeFunction, setChangesMade, parentIndex, defaultState, style }) {
  const [localState, setLocalState] = useState(defaultState)

  const styleTitle = {
    "fontSize": fontSize,
    "maxWidth": `${inputBoxSize}%`,
    "width": `${inputBoxSize}%`,
  }

  const styleInputBox = {
    "width": `${inputBoxSize}%`,
  }

  useEffect(() => {
    setLocalState(state[index].value)
  }, [state])

  const handleOnChange = (event) => {
    const inputValue = event.currentTarget.value

    setChangesMade && setChangesMade(true)

    switch (inputType) {

      case "number":
        !isNaN(inputValue) && setLocalState(inputValue)
        break

      case "general":
        try {
          // Create a deep copy of the state to avoid direct mutation
          const newState = JSON.parse(JSON.stringify(state))

          // Access and update the required value
          newState[index].value = inputValue

          // Set the new state
          setState(newState)
          setLocalState(inputValue)
        }
        catch (error) {
          console.log("No data to set")
        }
        break
    }


    // onChangeFunction()
  }

  return (
    <div className={`input_box__container ${textPosition == "vert" ? "vert" : ""} ${style}`} id={id}>
      {
        style == "mobile" ?
          <>
          <input className='input_text--mobile' placeholder={text} value={localState} onChange={handleOnChange} />
          </>
          :
          <>
            {
              textPosition == "right" ?
                <>
                  <input className='input_box__input' style={styleInputBox} />
                  <div className='input_box__title' style={styleTitle} value={localState} onChange={handleOnChange}>{text}</div>
                </> :

                <>
                  <div className='input_box__title' style={styleTitle}>{text}</div>
                  <input className='input_box__input' style={styleInputBox} value={localState} onChange={handleOnChange} />
                </>

            }
          </>
      }

    </div>
  )
}

export default InputCustomDetails

InputCustomDetails.defaultProps = {
  textPosition: "left",
  positionInput: "right",
  inputBoxSize: 50,
  fontSize: "0.8rem",
  inputType: "general",
  onChangeFunction: null,
  style: "normal",
}