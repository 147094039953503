import { persistStore } from 'redux-persist'
import httpClient from '../../httpClient'
import { store } from '../../store/store'
import { showNotificationPopup } from "../NotificationPopup"
import { showNotificationPopupMobile } from "../mobile/NotificationPopupMobile"
import { resetAppState } from '../../store/store'


let isChecking = false

export async function checkForUpdate(isMobile) {
  if (isChecking) {
    return false
  }

  const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/check-version`)
  if (!response.status == 200) {
    console.log('Error checking version')
    return false
  }

  const APP_VERSION = response.data.version

  isChecking = true

  const currentVersion = localStorage.getItem('app:version:stoqup')

  if (!currentVersion || currentVersion !== APP_VERSION) {
    // Version mismatch or not present, handle update
    if (currentVersion) {
      if (isMobile) {
        showNotificationPopupMobile({
          title: "Update available 🚀",
          message: "There is a new version of the app available. Please update to the new version.",
          actionButtonOnClick: () => {
            handleUpdate()
            localStorage.setItem('app:version:stoqup', APP_VERSION)
            console.log('App updated to version', APP_VERSION)
          },
          actionButtonText: "Update",
        })
      } else {
        showNotificationPopup({
          title: "Update available 🚀",
          message: "There is a new version of the app available. Please update to the new version.",
          actionButtonOnClick: () => {
            handleUpdate()
            localStorage.setItem('app:version:stoqup', APP_VERSION)
            console.log('App updated to version', APP_VERSION)
          },
          actionButtonText: "Update",
        })
      }
    } else {
      // If it's a fresh install (no previous version), set the current version
      localStorage.setItem('app:version:stoqup', APP_VERSION)
    }

    isChecking = false
    return true // Indicates an update is available
  } else {
    console.log('App version is current')
    isChecking = false
    return false // Indicates no update
  }
}

export async function purgePersist() {
  const persistor = persistStore(store)
  persistor.purge().then(() => {
    console.log('Persisted state has been purged.')
  }).catch(err => {
    console.error('Failed to purge persisted state:', err)
  })
}

export async function handleUpdate() {
  // Clear Redux state except user.userInfo
  resetAppState(true)

  // Clear IndexedDB
  await clearIndexedDB()

  // Purge persisted state
  await purgePersist()

  // Reload the page
  window.location.reload()
}

export async function clearIndexedDB() {
  return new Promise((resolve, reject) => {
    const databases = indexedDB.databases()
    databases.then((dbs) => {
      const deletePromises = dbs.map(({ name }) => 
        new Promise((res, rej) => {
          const request = indexedDB.deleteDatabase(name)
          request.onsuccess = res
          request.onerror = rej
        })
      )
      Promise.all(deletePromises).then(resolve).catch(reject)
    })
  })
}
